.code-box {
  width: 550px;
  height: 88px;
  background: #ffffff;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 40px;

  &-input {
    // margin-left: 40px;
    height: 88px;
    width: 350px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 44px;
  }

  &-button,
  &-tip {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FF4A00;
    line-height: 44px;
  }

  &-tip {
    color: #999999;
  }
}

input {
  outline: none;
  display: block;
  border: none;
  -webkit-appearance: none;
  // background: red;
}

// .adm-toast-main {
//   width: 420px;
// }

// .adm-auto-center {
//   width: 420px;
//   padding: 40px 90px 38px 90px;

//   &-content {
//     font-size: 30px;
//   }
// }