@font-face {
  font-family: "iconfont"; /* Project id 3701078 */
  src: url('../font/iconfont.woff2') format('woff2'),
       url('../font/iconfont.woff') format('woff'),
       url('../font/iconfont.ttf') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xianshi:before {
  content: "\e687";
}

.icon-cha:before {
  content: "\e688";
}

.icon-yincang:before {
  content: "\e689";
}

