.password-box {
  width: 550px;
  height: 88px;
  background: #ffffff;
  border-radius: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 0 40px;
  // padding-left: 20px;

  &-input {
    // margin-left: 40px;
    height: 88px;
    width: 516px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 44px;
  }
}

input {
  outline: none;
  display: block;
  border: none;
  -webkit-appearance: none;
  // background: red;
}