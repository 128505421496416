.login-container {
  background: #f4f4f4;
  width: 100%;
  // width: 750px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .type {
    // width: 175px;
    width: 350px;
    // padding: 100px;
    padding-top: 100px;
    padding-bottom: 80px;
    display: flex;
    justify-content: space-between;


    &-title {
      // width: 136px;
      height: 48px;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 48px;
    }

    &-line {
      width: 1px;
      height: 48px;
      background: #cccccc;
    }

    .active {
      color: #ff4a00;
    }
  }
}

.mobile-box {
  width: 550px;
  height: 88px;
  background: #ffffff;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 40px;

  &-input {
    // margin-left: 40px;
    height: 88px;
    width: 516px;
    // width: 550px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 44px;
    background: #ffffff;
    // border-radius: 44px;
    // padding: 0 40px;
    // margin-bottom: 40px;
  }
}

.adm-list {

  &-body {
    // width: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}