.submit {
  &-touch {
    width: 630px;
    height: 88px;
    background: linear-gradient(135deg, #FF4A00 0%, #FF8D54 100%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 272px;

    &-text {
      // width: 64px;
      height: 44px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 44px;
    }
  }

  &-gray {
    width: 630px;
    height: 88px;
    border-radius: 50px;
    border: 2px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 272px;

    &-text {
      // width: 64px;
      height: 44px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 44px;
    }
  }
}